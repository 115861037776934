import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useMediaQuery } from '@mui/material'
import * as classes from './FeedBackSection.module.scss'
import quoteIcon from '../../images/quote.svg'
import { graphql, useStaticQuery } from 'gatsby';

const ReviewCard = ({data})=>{

  return(
    <div className={classes.reviewCard}>
      <img alt="Quote" style={{top: 20, left: 20}} src={quoteIcon} className={classes.quoteIcon} />
      <img  alt="Quote" style={{bottom: 20, right: 20, transform: "rotate(180deg)"}} src={quoteIcon} className={classes.quoteIcon} />
      <div className={classes.profileImg}>
        <img src={data.profile_img.url} alt={data.profile_img.alt} />
      </div>
      <p className={classes.reviewText}>
        {data.review.text}
      </p>
      <div className={classes.horizontalLine} />
      <div className={classes.providerInfo}>
        <h3>{data.name.text}</h3>
        <h5>{data.degisnation.text}</h5>
      </div>
    </div>
  )
}

export default function FeedBackSection() {
  const matches = useMediaQuery('(min-width:1000px)');
  const data = useStaticQuery(graphql`
    {
      allPrismicFeedbacks {
        edges {
          node {
            uid
            data {
              degisnation {
                text
              }
              name {
                text
              }
              profile_img {
                url
                alt
              }
              review {
                text
              }
            }
          }
        }
      }
    }
  `)

  if(!data) {
    return null;
  }

  if(data.allPrismicFeedbacks.edges.length === 0) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.info}>
          <h1 className={classes.title}>
          Client Feedback
          </h1>
          <p className={classes.details}>
          Here are a handful of remarks from some of the early adopters of Experts Circle.
          </p>
        </div>

        <div className={classes.sliderContainer}>
          <Slider
          arrows={false} 
          dots 
          infinite 
          speed={500} slidesToShow={matches ? 2 : 1} slidesToScroll={1}>
            {data.allPrismicFeedbacks.edges.map(edge=>{
              const reviewData = edge.node.data
              console.log(edge.node.data)
              return (
                <div key={edge.node.uid}>
                  <div style={{padding: 10}}>
                    <ReviewCard data={reviewData} />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

