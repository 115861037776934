// extracted by mini-css-extract-plugin
export var root = "FeedBackSection-module--root--RlBeg";
export var title = "FeedBackSection-module--title--XgsA7";
export var details = "FeedBackSection-module--details--Q-ozk";
export var reviewCard = "FeedBackSection-module--reviewCard--jSu46";
export var profileImg = "FeedBackSection-module--profileImg--l+2Fi";
export var reviewText = "FeedBackSection-module--reviewText--konkV";
export var horizontalLine = "FeedBackSection-module--horizontalLine--xUqN-";
export var providerInfo = "FeedBackSection-module--providerInfo--61id3";
export var quoteIcon = "FeedBackSection-module--quoteIcon--+EqFD";
export var info = "FeedBackSection-module--info--aU-Ly";
export var sliderContainer = "FeedBackSection-module--sliderContainer--i2LJg";
export var container = "FeedBackSection-module--container--Iso0g";