import React from 'react';
import Typography from '../Typography/Typography';
import * as classes from './WhyWeSection.module.scss';

import imgsrc from '../../images/whywe.svg';

export default function WhyWeSection() {
  return (
    <div className={classes.whyWeSection}>
      <Typography variant="h1" align="center">
        Why Join Experts Circle?
      </Typography>
      {/* <Typography variant="h3" align="center" maxWidth>
      Experts Circle invites industry specialists to redefine recruitment by endorsing vetted talent, empowering employers to 
      connect with the finest candidates swiftly, without the overload of unrelated CVs.
      </Typography> */}

      <div className={classes.detailsSection}>  
      
        <Typography variant="p">
        Has the recruitment process become too stagnant, unchanged and unchallenged for decades? What if there was a transformative way forward? 
        Imagine empowering field experts to hand-select ideal candidates based on real knowledge of the job's demands. Move beyond traditional recruiters, 
        whose generic approaches may miss your specific needs, and explore the potential of recruiting through a verified expert who understands precisely what you require.
        </Typography>    
        <br/>          
      <Typography variant="p">
        <h3><u>Experts</u></h3>
        <p><b>Become an Expert Recruiter</b>: Utilize your industry knowledge and professional connections to open a new avenue for income and growth. 
          This role is designed to be managed alongside your current professional engagements, enriching your career without requiring a shift away from your primary job.</p>
        <ul>
          <li><b>Monetise Your Expertise:</b> Earn rewards and boost your credibility through our dynamic scoring system, recognising your effectiveness in recruitment.</li>
          <li><b>Amplify Your Professional Impact:</b> Increase your industry influence with minimal effort through our platform that facilitates precise, high-quality matches.</li>
          <li><b>Access Elite Opportunities:</b> Connect with prestigious employers and exclusive job openings, reinforcing your crucial role in the recruitment ecosystem.</li>
        </ul>
      </Typography>  
      <br/>
        <Typography variant="p">
        <h3><u>Employers</u></h3>             
        <b>Trust in Expertise</b>: Rely on the discernment of seasoned industry experts who not only understand the skills required but also ensure 
        that candidates' professional ethos aligns with your company culture. Our platform simplifies your recruitment process by:
          <ul>
            <li><b>Curated Talent Pools: </b> Access a handpicked selection of candidates whose skills and experiences have been meticulously verified by industry experts.</li>
            <li><b>Efficient Hiring: </b>Reduce the clutter of unrelated CVs and accelerate your hiring cycle with precision-matched candidates ready to excel in your specific roles.</li>
            <li><b>Streamline Your Hiring Process: </b> Utilise our platform to simplify every aspect of your recruitment workflow. From posting jobs to signing contracts, manage all your hiring activities in one secure, integrated space. This not only saves time but also enhances the security and compliance of your recruitment operations.</li>
          </ul>          
        </Typography>   
      </div>
      <Typography variant="p">
        <h4>Together, We Redefine Recruitment:</h4>          
          At Experts Circle, we blend cutting-edge technology with the human touch of industry expertise to forge a recruitment ecosystem that is efficient, reliable, and groundbreaking. Whether you're an employer aiming to fill a vacancy, a job seeker searching for your next career challenge, or an expert looking to leverage your knowledge as a recruiter, Experts Circle is your platform where potential meets opportunity and expertise drives success.
        </Typography>        
    </div>
  );
}
