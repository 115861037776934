// extracted by mini-css-extract-plugin
export var section1 = "index-module--section1--DUG9n";
export var heroSection = "index-module--heroSection--8tmAn";
export var mainText = "index-module--mainText--0MDCc";
export var moreInfo = "index-module--moreInfo--Qu9Wk";
export var actions = "index-module--actions--z5vtL";
export var getStartedBut = "index-module--getStartedBut--wQKT2";
export var learnMoreBut = "index-module--learnMoreBut--ugUev";
export var textContentLight = "index-module--textContentLight--kdREU";
export var textContentBold = "index-module--textContentBold--dJJeL";
export var yVideo = "index-module--yVideo--9DZIg";
export var features = "index-module--features--PxmRP";
export var featureCard = "index-module--featureCard--JpEv5";
export var featureImg = "index-module--featureImg--8taES";
export var lastSection = "index-module--lastSection--f+-7J";
export var textContent = "index-module--textContent--9Xi04";
export var featureDetails = "index-module--featureDetails--IocUF";